/**
 * Checks if a valid product is existing in the store
 * for the current flow (determined by the route).
 * If not, redirect to Step 1 of that flow
 */
export default defineNuxtRouteMiddleware((to) => {
    const shopStore = useShopStore();
    const flowStore = useFlowStore();
    const pathParts = to.path.split('/');

    // Get the product key from the route and check if a corresponding product exists
    if (!Object.keys(shopStore.products).includes(pathParts[pathParts.length - 2] ?? '')) {
        return navigateTo(getFirstFlowRoute(flowStore.flow?.steps));
    }
});
